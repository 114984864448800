import { newUser } from "./state";

const mutations = {
  SET_USER_EMPTY(state) {
    state.user = Object.assign({}, state.user, newUser());
  },
  SET_USER(state, data) {
    data.Password = "";
    state.user = Object.assign({}, state.user, data);
  },
};

export default mutations;
