import Axios from "axios";
import router from "@/router";

const actions = {
  actCheckAuth({ commit, state }) {
    const userId = state.user.UserId;
    if (userId == "") {
      commit("SET_USER_EMPTY");
      router.push("/Auth").catch(() => {});
    }
  },
  actLogout({ commit }) {
    commit("SET_USER_EMPTY");
    router.push("/Auth").catch(() => {});
  },
  async actLogin({ commit }, user) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user-table-auth`,
      user
    )
      .then((response) => {
        if (response.status == 200 && response.data.success) {
          commit("SET_USER", response.data.val[0]);
        }
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actChangePassword(store, user) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user-table-change-pass`,
      user
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export default actions;
