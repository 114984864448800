export const newUser = () => {
  return {
    UserId: "",
    FirstName: "",
    LastName: "",
    Password: "",
    Phone: "",
    Position: "",
    Status: 1,
    CreatedBy: "",
    CreatedDate: "",
    ModifiedBy: "",
    ModifiedDate: "",
    ConfirmPassword: "",
    OldPassword: "",
    IsAdmin: 0,
  };
};

const state = () => ({
  user: newUser(),
});

export default state;
