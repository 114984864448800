import Axios from "axios";
// import router from "@/router";

const actions = {
  ///////////////////////////////////////////////////  Parameter  /////////////////////////////////////////////////////////////
  async actParameterGets({ commit }) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/parametertable/getAll`
    )
      .then((response) => {
        console.log("");
        commit("SET_PARAMETER", response.data.result[0]);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },  
  async actParameterUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/parametertable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  Parameter  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  permission  /////////////////////////////////////////////////////////////
  async actPermissionGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/permission-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actPermissionCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/permission-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actPermissionUpdate(store, data) {
    console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/permission-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  permission  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  user  /////////////////////////////////////////////////////////////
  async actUserGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actUserCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actUserUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/user-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  user  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  printer  /////////////////////////////////////////////////////////////
  async actPrinterGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/printerTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actPrinterGet(store, data) {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/printerTable/get`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actPrinterCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/printerTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actPrinterUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/printerTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  printer  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  product  /////////////////////////////////////////////////////////////
  async actProductGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/productTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actProductGet(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/productTable/get`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actProductCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/productTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actProductUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/productTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  product  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  foodMenu  /////////////////////////////////////////////////////////////
  async actFoodMenuGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/menuTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actFoodMenuCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/menuTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actFoodMenuUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/menuTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  foodMenu  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  unit  /////////////////////////////////////////////////////////////
  async actUnitGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/unitTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actUnitCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/unitTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actUnitUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/unitTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  unit  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  warehouse  /////////////////////////////////////////////////////////////
  async actWarehouseGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/warehouseTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actWarehouseCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/warehouseTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actWarehouseUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/warehouseTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  warehouse  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  Tbl  /////////////////////////////////////////////////////////////
  async actTblGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actTblCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actTblUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  Tbl  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  cust  /////////////////////////////////////////////////////////////
  async actCustGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/cust-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actCustCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/cust-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actCustUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/cust-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  cust  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  supplier  /////////////////////////////////////////////////////////////
  async actSupplierGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/supplierTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actSupplierGet(store, data) {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/supplierTable/get`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actSupplierCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/supplierTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actSupplierUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/supplierTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  supplier  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  MenuType  /////////////////////////////////////////////////////////////
  async actMenuTypeGets() {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/menuTypeTable/getAll`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actMenuTypeCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/menuTypeTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actMenuTypeUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/menuTypeTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  MenuType  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  TBLType  /////////////////////////////////////////////////////////////
  async actTblTypeGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-type-table-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },  
  async actTblTypeGetsUse() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-type-table-gets-use`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actTblTypeCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-type-table-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actTblTypeUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/tbl-type-table-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  TBLType  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  Zone  /////////////////////////////////////////////////////////////
  async actZoneGets(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/zoneTable/getAll`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actZoneCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/zoneTable/create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actZoneUpdate(store, data) {
    console.log(data);
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/zoneTable/update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  MenuType  /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////  SysNumberFormat  /////////////////////////////////////////////////////////////
  async actSysNumberFormatGets() {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/sysnumbersequenceformat-gets`,
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actSysNumberFormatCreate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/sysnumbersequenceformat-create`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actSysNumberFormatUpdate(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/sysnumbersequenceformat-update`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  ///////////////////////////////////////////////////  SysNumberFormat  /////////////////////////////////////////////////////////////
};

export default actions;
