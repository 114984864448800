import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Auth",
    // name: "Auth",
    component: () => import("../layouts/Auth.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("../views/Login.vue")
      }
    ]
  },
  {
    path: "",
    // name: "",
    component: () => import("../layouts/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/SettingPermission",
        name: "SettingPermission",
        component: () => import("../views/settings/permission/settingPermission/SettingPermission.vue"),
      },
      {
        path: "/SettingUser",
        name: "SettingUser",
        component: () => import("../views/settings/user/settingUser/SettingUser.vue"),
      },
      {
        path: "/SettingPrinter",
        name: "SettingPrinter",
        component: () => import("../views/settings/permission/settingPrinter/SettingPrinter.vue"),
      },
      {
        path: "/SettingRawMaterial",
        name: "SettingRawMaterial",
        component: () => import("../views/settings/product/settingRawMaterial/SettingRawMaterial.vue"),
      },
      {
        path: "/SettingFoodMenu",
        name: "SettingFoodMenu",
        component: () => import("../views/settings/product/settingFoodMenu/SettingFoodMenu.vue"),
      },
      {
        path: "/SettingUnit",
        name: "SettingUnit",
        component: () => import("../views/settings/product/settingUnit/SettingUnit.vue"),
      },
      {
        path: "/SettingParameter",
        name: "SettingParameter",
        component: () => import("../views/settings/permission/settingParameter/SettingParameter.vue"),
      },
      {
        path: "/SettingSysNumberFormat",
        name: "SettingSysNumberFormat",
        component: () => import("../views/settings/permission/settingSysNumberFormat/SettingSysNumberFormat.vue"),
      },
      {
        path: "/SettingWarehouse",
        name: "SettingWarehouse",
        component: () => import("../views/settings/product/settingWarehouse/SettingWarehouse.vue"),
      },
      {
        path: "/SettingZone",
        name: "SettingZone",
        component: () => import("../views/settings/front/settingZone/SettingZone.vue"),
      },
      {
        path: "/SettingTbl",
        name: "SettingTbl",
        component: () => import("../views/settings/front/settingTbl/SettingTbl.vue"),
      },
      {
        path: "/SettingTBLType",
        name: "SettingTBLType",
        component: () => import("../views/settings/front/settingTBLType/SettingTBLType.vue"),
      },
      {
        path: "/SettingCust",
        name: "SettingCust",
        component: () => import("../views/settings/front/settingCust/SettingCust.vue"),
      },
      {
        path: "/SettingSupplier",
        name: "SettingSupplier",
        component: () => import("../views/settings/product/settingSupplier/settingSupplier.vue"),
      },
      {
        path: "/SettingMenuType",
        name: "SettingMenuType",
        component: () => import("../views/settings/product/settingMenuType/SettingMenuType.vue"),
      },
      {
        path: "/ReceiveRecord",
        name: "ReceiveRecord",
        component: () => import("../views/transactions/doccument/receiveRecord/receiveRecord.vue"),
      },
      {
        path: "/EditReceiveRecord",
        name: "EditReceiveRecord",
        props: true,
        component: () => import("../views/transactions/doccument/receiveRecord/editReceiveRecord.vue"),
      },
      {
        path: "/OrderTable",
        name: "OrderTable",
        props: true,
        component: () => import("../views/transactions/doccument/orderTable/orderTable.vue"),
      },
      // {
      //   path: "/shipment",
      //   name: "Shipment",
      //   component: () => import("../views/bill/shipment/shipment.vue")
      // }
    ]
  }
];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes
// });
const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  publicPathmode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  routes,
});

export default router;
